import { TFunction } from "i18next";

export enum MatchAttributeType {
  PROMOTION = "PROMOTION_SCORE",
  SALARY = "SALARY_VELOCITY_SCORE",
  TENURE = "TENURE_SCORE",
  LOCATION = "LOCATION_FILTER",
}

export enum JobMatchStatus {
  MATCHED = "MATCHED",
  HIRED = "HIRED",
  APPLIED = "APPLIED",
}

export enum MatchedApplicantState {
  UNMATCH = "UNMATCH",
  BOOKMARK = "BOOKMARK",
}

export const getJobMatchStatusString = (
  jobMatchStatus: string,
  t: TFunction,
) => {
  switch (jobMatchStatus) {
    case JobMatchStatus.MATCHED:
      return t("jobMatchStatus.matched", { ns: "common" });
    case JobMatchStatus.HIRED:
      return t("jobMatchStatus.hired", { ns: "common" });
    case JobMatchStatus.APPLIED:
      return t("jobMatchStatus.applied", { ns: "common" });
    default:
      return t("jobMatchStatus.unknown", { ns: "common" });
  }
};
