import { useTranslation } from "react-i18next";
import { useState, useContext } from "react";
import "react-toastify/dist/ReactToastify.css";
import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";
import Input from "../../theme/Input";
import "../../CustomToastAnimations.css";
import { Translation } from "../../lib/constants";
import theme from "../../theme";
import TextLink from "../../theme/TextLink";
import { signIn, SignInRequest } from "../../api/auth";
import { UserContext } from "../../lib/context";
import { LoginEvent } from "../../lib/eventEnums";
import { setUserInStorage, trackGoogleAnalyticsEvent } from "../../lib/utils";
import { HttpError } from "../../models/api/http";
import { useNavigate } from "react-router-dom";
import Typography from "@mui/material/Typography";
import ErrorMessage from "../../theme/ErrorMessage";
import MuiButton from "@mui/material/Button";
import arrowLeft from "/public/arrow-left.svg";
import Box from "@mui/material/Box";

type props = {
  showMfaInput: () => void;
  showPasswordInput: () => void;
  loginData: SignInRequest;
  setLoginData: (data: SignInRequest) => void;
};

export default function PasswordForm({
  loginData,
  setLoginData,
  showMfaInput,
  showPasswordInput,
}: props) {
  const { t } = useTranslation(Translation.login);
  const navigate = useNavigate();
  const [_, setUser] = useContext(UserContext);

  const [passwordError, setPasswordError] = useState("");
  const [submitError, setSubmitError] = useState("");
  const [loading, setLoading] = useState(false);

  const validateForm = () => {
    if (loginData.password.length === 0) {
      setPasswordError(t("passwordRequired"));
      return false;
    }
    return true;
  };

  const handleSubmit = async () => {
    if (!validateForm()) return;

    try {
      setLoading(true);
      const response = await signIn(loginData);
      if (response.user) {
        // users logging into Boomerang app should only have one org
        const org = response.user.orgs[0] ?? {};
        const user = {
          email: response.user.email,
          firstName: response.user.first_name,
          lastName: response.user.last_name,
          orgId: org.org_id,
          orgName: org.org_name,
        };
        setUser(user);
        setUserInStorage(user);
        trackGoogleAnalyticsEvent({
          event: LoginEvent.LOGIN,
          org: user.orgName,
          name: `${user.firstName}` + `${user.lastName}`,
        });
        navigate("/");
        return;
      } else if (response.is_mfa_required == true) {
        showMfaInput();
      }
    } catch (error: any) {
      if (error instanceof HttpError && error.status === 401) {
        setSubmitError(t("invalidCredentials"));
      } else {
        setSubmitError(t("error.unexpected", { ns: Translation.common }));
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {submitError !== "" && <ErrorMessage error={submitError} />}

      <Stack width="100%">
        <Stack
          direction="row"
          gap="0.5rem"
          justifyContent="center"
          alignItems="center"
          onClick={showPasswordInput}
          sx={{
            border: "1px solid",
            borderColor: "white",
            cursor: "pointer",
            width: "fit-content",
          }}
        >
          <Box component="img" src={arrowLeft} />

          <Typography
            fontSize="14px"
            fontWeight={600}
            color={theme.palette.grey[600]}
          >
            {t("back")}
          </Typography>
        </Stack>

        <Stack
          direction="column"
          marginY="1rem"
          justifyContent="center"
          gap="0.5rem"
        >
          <Typography
            className="input-label"
            fontSize="0.875rem"
            fontWeight={500}
          >
            {t("email")}
          </Typography>

          <Typography
            className="input-label"
            fontSize="1rem"
            fontWeight={500}
            color={theme.palette.grey[500]}
          >
            {loginData.email}
          </Typography>
        </Stack>

        <Input
          label={t("password")}
          placeholder={t("passwordPlaceholder")}
          onChange={(event) => {
            setLoginData({ ...loginData, password: event.target.value });
          }}
          onKeyUp={(event) => { if (event.key === "Enter") handleSubmit() }}
          error={passwordError}
          stackProps={{ marginBottom: "1rem" }}
          inputProps={{ name: "password", type: "password" }}
        />
        <TextLink
          text={t("forgot")}
          linkProps={{
            to: "/forgot-password",
            style: {
              margin: "1rem 0",
              color: theme.palette.primary.main,
            },
          }}
        />
        <MuiButton
          variant="contained"
          type="button"
          disabled={loading}
          id="password-form-next-button"
          sx={{
            height: "2.5rem",
            backgroundColor: theme.palette.primary.main,
            borderRadius: "0.5rem",
            boxShadow: "none",
            "&:hover": {
              boxShadow: "none",
            },
            "&:disabled": {
              backgroundColor: theme.palette.custom.purpleDisabled,
              color: theme.palette.common.white,
            },
            transition: "none",
          }}
          onClick={handleSubmit}
        >
          {loading ? <CircularProgress size="1rem" /> : t("cta")}
        </MuiButton>
      </Stack>
    </>
  );
}
