import * as React from "react";
import styled from "@emotion/styled";
import Menu, { MenuProps } from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import {
  Box,
  Divider,
  IconButton,
  ListItemIcon,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import theme from "../../theme";
import { AlumDTO } from "../../models/api/alum.ts";

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(() => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      padding: ".6rem .7rem",
      marginLeft: "4px",
      marginRight: "4px",
      borderRadius: "5px",
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
    },
    "& .MuiDivider-root": {
      marginTop: "4px",
      marginBottom: "4px",
    },
  },
}));

interface MenuItem {
  className?: string;
  label: string;
  handler: () => void | ((alum: AlumDTO) => void);
  hasDividerAbove: boolean;
  color?: string;
  icon?: React.ReactNode;
  disabled?: boolean;
}

type OptionsMenuProps = Omit<MenuProps, "open"> & {
  items: MenuItem[];
  disabled?: boolean;
};

export default function DropdownMenu(props: OptionsMenuProps) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const openMenu = (event: React.MouseEvent<HTMLElement>) => {
    if (props.disabled) return;
    setAnchorEl(event.currentTarget);
  };
  const closeMenu = () => {
    setAnchorEl(null);
  };
  const handleAndClose = (clickHandler: () => void) => () => {
    setAnchorEl(null);
    clickHandler();
  };

  return (
    <Box {...props} className="options-menu">
      <IconButton
        disableRipple
        aria-haspopup="true"
        onClick={openMenu}
        className="options-menu-button"
        disabled={props.disabled}
      >
        <MoreVertIcon color={props.disabled ? "disabled" : "inherit"} />
      </IconButton>

      <StyledMenu anchorEl={anchorEl} open={open} onClose={closeMenu}>
        {props.items.map((menuItem, ind) => (
          <Box key={ind}>
            {menuItem.hasDividerAbove && <Divider />}
            <MenuItem
              disabled={menuItem.disabled}
              className={menuItem.className}
              onClick={handleAndClose(menuItem.handler)}
              disableRipple={true}
              style={{ fontSize: "14px", color: menuItem.color }}
            >
              {menuItem.icon ? (
                <ListItemIcon>{menuItem.icon}</ListItemIcon>
              ) : (
                menuItem.icon
              )}
              {menuItem.label}
            </MenuItem>
          </Box>
        ))}
      </StyledMenu>
    </Box>
  );
}
