import { useTranslation } from "react-i18next";
import { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import ErrorMessage from "../../theme/ErrorMessage";
import theme from "../../theme";
import Input from "../../theme/Input";
import MuiButton from "@mui/material/Button";
import { signIn, SignInRequest } from "../../api/auth";
import { setUserInStorage, trackGoogleAnalyticsEvent } from "../../lib/utils";
import { HttpError } from "../../models/api/http";
import "../../CustomToastAnimations.css";
import { Translation } from "../../lib/constants";
import { LoginEvent } from "../../lib/eventEnums";
import { UserContext } from "../../lib/context";

type props = {
  loginData: SignInRequest;
  setLoginData: (data: SignInRequest) => void;
};

export default function MFAForm({ loginData, setLoginData }: props) {
  const { t } = useTranslation(Translation.login);
  const [_, setUser] = useContext(UserContext);
  const navigate = useNavigate();

  const [mfaCodeError, setMfaCodeError] = useState("");
  const [submitError, setSubmitError] = useState("");
  const [loading, setLoading] = useState(false);

  const validateMfa = () => {
    if (loginData.mfaCode?.length !== 6) {
      setMfaCodeError(t("mfaRequired"));
      return false;
    }
    return true;
  };

  const handleSubmit = async () => {
    if (!validateMfa()) return;

    try {
      setLoading(true);
      const response = await signIn(loginData);
      if (response.user) {
        // users logging into Boomerang app should only have one org
        const org = response.user.orgs[0] ?? {};
        const user = {
          email: response.user.email,
          firstName: response.user.first_name,
          lastName: response.user.last_name,
          orgId: org.org_id,
          orgName: org.org_name,
        };
        setUser(user);
        setUserInStorage(user);
        trackGoogleAnalyticsEvent({
          event: LoginEvent.LOGIN,
          org: user.orgName,
          name: `${user.firstName}` + `${user.lastName}`,
        });
        navigate("/");
        return;
      }
    } catch (error: any) {
      if (error instanceof HttpError && error.status === 401) {
        setSubmitError(t("invalidMFA"));
      } else {
        setSubmitError(t("error.unexpected", { ns: Translation.common }));
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {submitError !== "" && (
        <ErrorMessage
          error={submitError}
          stackProps={{ marginBottom: "2rem" }}
        />
      )}

      <Box width="100%">
        <Input
          label={t("securityCode")}
          placeholder={t("securityCodePlaceholder")}
          onChange={(event) => {
            setLoginData({ ...loginData, mfaCode: event.target.value });
          }}
          onKeyUp={(event) => { if (event.key === "Enter") handleSubmit() }}
          error={mfaCodeError}
          stackProps={{ height: "6.5rem" }}
          inputProps={{ name: "mfaCode" }}
        />
        <MuiButton
          variant="contained"
          type="button"
          disabled={loading}
          id="mfa-form-next-button"
          sx={{
            height: "2.5rem",
            backgroundColor: theme.palette.primary.main,
            borderRadius: "0.5rem",
            boxShadow: "none",
            "&:hover": {
              boxShadow: "none",
            },
            "&:disabled": {
              backgroundColor: theme.palette.custom.purpleDisabled,
              color: theme.palette.common.white,
            },
            transition: "none",
          }}
          onClick={handleSubmit}
        >
          {loading ? <CircularProgress size="1rem" /> : t("cta")}
        </MuiButton>
      </Box>
    </>
  );
}
