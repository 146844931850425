import { AuthUser, OidcClientConfig } from "../models/auth";
import ReactGA from "react-ga4";
import { GAEvent } from "./eventEnums";
import {
  PayPeriodEnum,
  PayPeriodRounding,
  PayPeriodSuffix,
  PayPeriodYearConversion,
} from "../models/api/job";
import { TFunction } from "i18next";

const HTML_SPECIAL_CHARACTERS_REGEX = /&#\w+;|<br\s*\/?>|\r?\n/g;
const EMAIL_REGEX = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
const US_STATE_COUNTRY_ABBR_REGEX = new RegExp(
  /\b(?:AL|AK|AZ|AR|CA|CO|CT|DE|FL|GA|HI|ID|IL|IN|IA|KS|KY|LA|ME|MD|MA|MI|MN|MS|MO|MT|NV|NH|NJ|NM|NY|NC|ND|OH|OK|OR|PA|RI|SC|SD|TN|TX|UT|VT|VA|WA|WV|WI|WY|NE|US|USA)\b/,
  "i",
);

const connectingWords = new Set([
  "a",
  "an",
  "and",
  "as",
  "at",
  "but",
  "by",
  "for",
  "if",
  "in",
  "nor",
  "of",
  "on",
  "or",
  "so",
  "the",
  "to",
  "up",
  "yet",
]);

const LOCALSTORAGE_USER_KEY = "user";
const LOCALSTORAGE_OIDC_CONFIG_KEY = "oidcConfig";
const LOCALSTORAGE_CSRF_KEY = "csrfToken";
const SESSIONSTORAGE_ROLE_VIEW_SORT = "roleViewSort";
const SESSIONSTORAGE_ROLE_VIEW_ALUMNI_MATCHES_SORT =
  "roleViewAlumniMatchesSort";
const SESSIONSTORAGE_ALUMNI_DB_SORT = "alumniDbSort";

export const NO_DEPARTMENT_OPTION = "NO_DEPARTMENT";

export const USER_GUIDE_URL =
  "https://hiboomerang.notion.site/6cf4827b31794f7d8feee756256397af?v=b8c1752a0732403fa3231f9e46a0f08a&pvs=4";

export const PAGE_SIZE = 10;

export const ALUMNI_DATABASE_PAGE_SIZE = 30;
export const MAX_SELECTED_ALUMNI = 20;
export const ALUMNI_DATABASE_SEARCH_SUGGESTIONS_LIMIT = 10;

export const DEBOUNCE_DELAY = 700;

export const loadUserFromStorage = (): AuthUser | null => {
  const user = localStorage.getItem(LOCALSTORAGE_USER_KEY);
  return user ? (JSON.parse(user) as AuthUser) : null;
};

export const setUserInStorage = (user: AuthUser) => {
  localStorage.setItem(LOCALSTORAGE_USER_KEY, JSON.stringify(user));
};

export const removeUserFromStorage = () => {
  localStorage.removeItem(LOCALSTORAGE_USER_KEY);
};

export const loadOidcClientConfigFromStorage = (): OidcClientConfig | null => {
  const data = localStorage.getItem(LOCALSTORAGE_OIDC_CONFIG_KEY);
  return data ? (JSON.parse(data) as OidcClientConfig) : null;
};

export const setOidcClientConfigInStorage = (config: OidcClientConfig) => {
  localStorage.setItem(LOCALSTORAGE_OIDC_CONFIG_KEY, JSON.stringify(config));
};

export const removeOidcClientConfigFromStorage = () => {
  localStorage.removeItem(LOCALSTORAGE_OIDC_CONFIG_KEY);
};

export const loadCsrfTokenFromStorage = (): string => {
  return localStorage.getItem(LOCALSTORAGE_CSRF_KEY) ?? "";
};

export const setCsrfTokenInStorage = (csrfToken: string) => {
  localStorage.setItem(LOCALSTORAGE_CSRF_KEY, csrfToken);
};

export const loadRoleSortFromStorage = (): string => {
  return (
    sessionStorage.getItem(SESSIONSTORAGE_ROLE_VIEW_SORT) ?? "sortOption.newest"
  );
};

export const loadRoleViewAlumniMatchesSortFromStorage = (): string => {
  return (
    sessionStorage.getItem(SESSIONSTORAGE_ROLE_VIEW_ALUMNI_MATCHES_SORT) ??
    "sortOption.strongestMatch"
  );
};

export const setRoleSortInStorage = (sortValue: string) => {
  sessionStorage.setItem(SESSIONSTORAGE_ROLE_VIEW_SORT, sortValue);
};

export const setRoleViewAlumniMatchesSortInStorage = (sortValue: string) => {
  sessionStorage.setItem(
    SESSIONSTORAGE_ROLE_VIEW_ALUMNI_MATCHES_SORT,
    sortValue,
  );
};

export const loadAlumniDbSortFromStorage = (): string => {
  return (
    sessionStorage.getItem(SESSIONSTORAGE_ALUMNI_DB_SORT) ??
    "sortOption.departureDateNewest"
  );
};

export const setAlumniDbSortInStorage = (sortValue: string) => {
  sessionStorage.setItem(SESSIONSTORAGE_ALUMNI_DB_SORT, sortValue);
};

export const urlDecodeBase64 = (urlEncoded: string): string => {
  return urlEncoded
    .replaceAll("~", "+")
    .replaceAll("_", "/")
    .replaceAll("-", "=");
};

export const validateEmail = (email: string): boolean => {
  return EMAIL_REGEX.test(email);
};

export const isUsStateOrCountryAbbr = (state: string): boolean => {
  return US_STATE_COUNTRY_ABBR_REGEX.test(state);
};

export const findIndexOfLongestPrefix = (
  prefixes: string[],
  str: string,
): number => {
  let maxLength = 0;
  let indexOfMax = -1;
  prefixes.forEach((prefix, index) => {
    if (str.startsWith(prefix) && prefix.length > maxLength) {
      maxLength = prefix.length;
      indexOfMax = index;
    }
  });
  return indexOfMax;
};

export const capitalize = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

export const capitalizeFirstLetter = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

const isConnectingWord = (str: string) => {
  return connectingWords.has(str.toLowerCase());
};

export const titleCase = (str: string) => {
  return capitalizeFirstLetter(
    str
      .split(" ")
      .map((str) =>
        isConnectingWord(str)
          ? str.toLocaleLowerCase()
          : capitalizeFirstLetter(str),
      )
      .join(" "),
  );
};

export const isOverflowX = (element: HTMLElement) => {
  return element.offsetWidth < element.scrollWidth;
};

interface GoogleAnalyticsEventParams {
  event: GAEvent;
  name?: string;
  type?: string;
  org?: string;
  message?: string;
  customParameters?: { [key: string]: any };
}

export const trackGoogleAnalyticsEvent = ({
  event,
  name,
  type,
  org,
  message,
  customParameters,
}: GoogleAnalyticsEventParams) => {
  try {
    const eventParams = {
      name,
      type,
      org,
      message,
      ...customParameters,
    };
    ReactGA.event(String(event), eventParams);
  } catch {
    console.error("Failed to send GA event");
  }
};

export const isInteger = (str: string) => {
  str = str.trim();
  if (!str) {
    return false;
  }
  str = str.replace(/^0+/, "") || "0";
  var n = Math.floor(Number(str));
  return n !== Infinity && String(n) === str && n >= 0;
};

export const isNumeric = (str: string) => {
  return (
    // @ts-ignore
    !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
    !isNaN(parseFloat(str))
  ); // ...and ensure strings of whitespace fail
};

export const convertToAnnualSalary = (payRate: number, payPeriod: string) => {
  var payPeriodEnum = PayPeriodEnum[payPeriod as keyof typeof PayPeriodEnum];
  if (!payPeriodEnum) {
    return payRate;
  }

  return payRate * PayPeriodYearConversion[payPeriodEnum];
};

export const formatPayRate = (
  payRate: number,
  payPeriod: string,
  t: Function,
  language: string,
) => {
  var payPeriodEnum = PayPeriodEnum[payPeriod as keyof typeof PayPeriodEnum];
  if (!payPeriodEnum) {
    return `$${payRate.toLocaleString()}`;
  }

  switch (payPeriodEnum) {
    case PayPeriodEnum.HOUR:
    case PayPeriodEnum.DAY:
    case PayPeriodEnum.WEEK:
    case PayPeriodEnum.EVERY_TWO_WEEKS:
    case PayPeriodEnum.SEMIMONTHLY:
    case PayPeriodEnum.MONTH:
    case PayPeriodEnum.QUARTER:
    case PayPeriodEnum.EVERY_SIX_MONTHS:
      return `$${payRate.toLocaleString(language, {
        maximumFractionDigits: PayPeriodRounding[payPeriodEnum],
      })}${t(PayPeriodSuffix[payPeriodEnum])}`;
    case PayPeriodEnum.YEAR:
    default:
      return `$${payRate.toLocaleString(language, {
        maximumFractionDigits: PayPeriodRounding[PayPeriodEnum.YEAR],
      })}`;
  }
};

export const formatAnnuPay = (
  payRate: number,
  payPeriod: string,
  t: TFunction,
  language: string,
) => {
  return `$${convertToAnnualSalary(payRate, payPeriod).toLocaleString(
    language,
    {
      maximumFractionDigits: 0,
    },
  )} ${t("annu")}`;
};

export const buildUrl = (url: string) => {
  if (url.startsWith("http")) {
    return url;
  } else {
    return `https://${url}`;
  }
};

export function areSetsEqual<T>(setA: Set<T>, setB: Set<T>): boolean {
  if (setA.size !== setB.size) return false;
  for (let item of setA) {
    if (!setB.has(item)) return false;
  }
  return true;
}

export function areArraysEqual<T>(arrayA: T[], arrayB: T[]): boolean {
  if (arrayA.length !== arrayB.length) return false;
  for (let item of arrayA) {
    if (!arrayB.includes(item)) return false;
  }

  for (let item of arrayB) {
    if (!arrayA.includes(item)) return false;
  }
  return true;
}

export function areMapsEqual<K, V>(mapA: Map<K, V>, mapB: Map<K, V>): boolean {
  if (mapA.size !== mapB.size) return false;
  for (let [key, value] of mapA) {
    if (!mapB.has(key) || mapB.get(key) !== value) return false;
  }
  return true;
}

export const Format = {
  uppercaseFirstLetter: (value: string) => titleCase(value),
  capitalize: (value: string) => capitalize(value),
  location: (value: string) =>
    value
      .replace(HTML_SPECIAL_CHARACTERS_REGEX, ', ')
      .split(" ")
      .map((word) =>
        isUsStateOrCountryAbbr(word)
          ? word.toUpperCase()
          : capitalizeFirstLetter(word),
      )
      .join(" "),
  dateMonthYear: (value: string, language?: string) => {
    return new Date(value).toLocaleDateString(language, {
      month: "long",
      year: "numeric",
    });
  },
  dateDayMonthYear: (value: string, language?: string) => {
    return new Date(value).toLocaleDateString(language, {
      month: "short",
      day: "numeric",
      year: "numeric",
    });
  },
  dateLongDayMonthYear: (value: string, language?: string) => {
    return new Date(value).toLocaleDateString(language, {
      month: "long",
      day: "numeric",
      year: "numeric",
    });
  },
  dateAsRelativeTime: (value: string, language?: string) => {
    const displayDateParts = [];

    const date = new Date(value);
    const today = new Date();

    const diffDays = Math.round(
      (date.getTime() - today.getTime()) / (1000 * 60 * 60 * 24),
    );

    const formatter = new Intl.RelativeTimeFormat(language, {
      numeric: "auto",
    });

    if (Math.abs(diffDays) <= 1) {
      displayDateParts.push(
        Format.capitalize(formatter.format(diffDays, "day")),
      );
    } else {
      displayDateParts.push(Format.dateLongDayMonthYear(value, language));
    }

    return displayDateParts.join(" ");
  },
};

export const milesToKilometers = (inMiles: number | null): number => {
  return inMiles ? inMiles * 1.60934 : 0;
}

export const kilometersToMiles = (inKilometers: number | null): number => {
  return inKilometers ? inKilometers / 1.60934 : 0;
}