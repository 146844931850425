import Stack from "@mui/material/Stack";
import { JobAlumniMatchDTO } from "../../models/api/job";
import Typography from "@mui/material/Typography";
import Checkbox, { CheckedState } from "../../components/Checkbox";
import Avatar, { AvatarSize } from "../../theme/Avatar";
import MatchAttributePill from "../../theme/MatchAttributePill";
import {
  JobMatchStatus,
  MatchAttributeType,
  getJobMatchStatusString,
  MatchedApplicantState,
} from "../../models/job";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";
import Table, { TableColumn } from "../../components/Table";
import Tooltip from "../../theme/Tooltip";
import { Translation } from "../../lib/constants";
import NewTag from "../../theme/NewTag";
import { useContext } from "react";
import { FlagsContext, UserConfigContext } from "../../lib/context";
import { FormattedText, FormattedTextType } from "../../theme/FormattedText";
import { Format } from "../../lib/utils";
import theme from "../../theme";
import SalaryText from "../../components/SalaryText";
import BriefcaseIcon from "/dark-briefcase.svg";
import MailIcon from "/mail.svg";
import BookmarkIcon from "/bookmark.svg";
import BookmarkFilledGreyIcon from "/bookmark-filled.svg";
import BookmarkFilledPurpleIcon from "/bookmark-filled-purple.svg";
import BookmarkMinusIcon from "/bookmark-minus.svg";
import UserCheckIcon from "/user-check-01.svg";
import UserXIcon from "/user-x-01.svg";
import Menu from "../../components/OptionsMenu";

interface RolesViewTableProps {
  jobMatches: JobAlumniMatchDTO[];
  page: number;
  pageSize: number;
  totalRows: number;
  onPageChange: (page: number) => void;
  onCheckboxClick: (count: number) => void;
  isRoleClosed: boolean;
  selectedIds: Set<string>;
  setSelectedIds: (newSelectedIds: Set<string>) => void;
  onClick: (alumId: string) => void;
  onAlumniSendRole: (jobMatch: JobAlumniMatchDTO) => void;
  onAlumniSendMessage: (jobMatch: JobAlumniMatchDTO) => void;
  onAlumniMatch: (jobMatch: JobAlumniMatchDTO) => void;
  onAlumniRemoveMatch: (jobMatch: JobAlumniMatchDTO) => void;
  onAlumniBookmark: (jobMatch: JobAlumniMatchDTO) => void;
  onAlumniRemoveBookmark: (jobMatch: JobAlumniMatchDTO) => void;
  tabValue: number;
}

export default function RolesViewTable({
  jobMatches,
  page,
  pageSize,
  totalRows,
  onPageChange,
  onCheckboxClick,
  isRoleClosed,
  selectedIds,
  setSelectedIds,
  onClick,
  onAlumniSendRole,
  onAlumniSendMessage,
  onAlumniBookmark,
  onAlumniRemoveBookmark,
  onAlumniMatch,
  onAlumniRemoveMatch,
  tabValue
}: RolesViewTableProps) {
  const { t } = useTranslation(Translation.rolesView);
  const [userConfig] = useContext(UserConfigContext);
  const [flags] = useContext(FlagsContext);

  const generateMenuOptions = (jobAlumniMatch: JobAlumniMatchDTO) => {
    const options = [
      {
        className: "send-role-option",
        label: t("menuOptions.sendRole.label"),
        handler: () => onAlumniSendRole(jobAlumniMatch),
        hasDividerAbove: false,
        icon: <Box component="img" src={BriefcaseIcon} />,
      },
      {
        className: "send-message-option",
        label: t("menuOptions.sendMessage.label"),
        handler: () => onAlumniSendMessage(jobAlumniMatch),
        hasDividerAbove: false,
        icon: <Box component="img" src={MailIcon} />,
      },
    ];

    if (showAlumniActions()) {
      if (jobAlumniMatch.state !== MatchedApplicantState.BOOKMARK) {
        options.push({
          className: "bookmark-option",
          label: t("menuOptions.bookmark.label"),
          handler: () => onAlumniBookmark(jobAlumniMatch),
          hasDividerAbove: true,
          icon: <Box component="img" src={BookmarkIcon} />,
        });
      } else {
        options.push({
          className: "remove-bookmark-option",
          label: t("menuOptions.removeBookmark.label"),
          handler: () => onAlumniRemoveBookmark(jobAlumniMatch),
          hasDividerAbove: true,
          icon: <Box component="img" src={BookmarkMinusIcon} />,
        });
      }

      if (jobAlumniMatch.state === MatchedApplicantState.UNMATCH) {
        options.splice(0, 3);
        options.push({
          className: "match-option",
          label: t("menuOptions.restoreMatch.label"),
          handler: () => onAlumniMatch(jobAlumniMatch),
          hasDividerAbove: false,
          icon: <Box component="img" src={UserCheckIcon} />,
        });
      } else {
        options.push({
          className: "unmatch-option",
          label: t("menuOptions.unmatch.label"),
          handler: () => onAlumniRemoveMatch(jobAlumniMatch),
          hasDividerAbove: true,
          icon: <Box component="img" src={UserXIcon} />,
        });
      }
    }
    return options;
  };

  const showAlumniActions = () => {
    return flags?.includes("ALUMNI_FAVORITES") ?? false;
  };

  // Check to see if we want to show last Known Role and other post-departure related fields.
  const showPostDepartureDataRelatedFields = () => {
    return flags?.includes("SHOW_POST_DEPARTURE_DATA") ?? false;
  };

  const orgName = jobMatches.length > 0 ? jobMatches[0].org_name : "";

  // Pagination on FE, may need to move to BE if performance is an issue
  const startIndex = page * pageSize;
  const endIndex = startIndex + pageSize;
  const paginatedJobMatches = jobMatches.slice(startIndex, endIndex);

  const allCheckedOnPage =
    jobMatches.length > 0 &&
    jobMatches.every((jobMatch) => selectedIds.has(jobMatch.applicant_id));

  const someCheckedOnPage =
    !allCheckedOnPage &&
    jobMatches.some((jobMatch) => selectedIds.has(jobMatch.applicant_id));

  const checkOrUncheckRow = (id: string) => {
    const newSelectedIds = new Set(selectedIds);
    if (newSelectedIds.has(id)) {
      newSelectedIds.delete(id);
    } else {
      newSelectedIds.add(id);
    }
    setSelectedIds(newSelectedIds);
    onCheckboxClick(newSelectedIds.size);
  };

  const checkOrUncheckOnPage = () => {
    const newSelectedIds = new Set(selectedIds);
    const selectableJobMatches = jobMatches.filter(
      (jobMatch) => jobMatch.status !== JobMatchStatus.HIRED,
    );
    // Uncheck all if all are checked, or some are checked
    if (allCheckedOnPage || someCheckedOnPage) {
      selectableJobMatches.forEach((jobMatch) => {
        newSelectedIds.delete(jobMatch.applicant_id);
      });
    }
    // Otherwise check all
    else {
      selectableJobMatches.forEach((jobMatch) => {
        if (jobMatch.is_subscribed) {
          newSelectedIds.add(jobMatch.applicant_id);
        }
      });
    }
    setSelectedIds(newSelectedIds);
    onCheckboxClick(newSelectedIds.size);
  };

  // If PDD is enabled but the current role is the same as the last role at the org,
  // and the current company is the same as the current org, we should render no text for
  // both the last role at org and the current company.
  const renderCorrectCurrentJobTitleText = (jobMatch: JobAlumniMatchDTO) => {
    if (
      jobMatch.last_role_at_org === jobMatch.current_job_title &&
      jobMatch.current_company === orgName
    ) {
      return "";
    }
    return jobMatch.current_job_title;
  };

  const renderCorrectCurrentCompanyText = (jobMatch: JobAlumniMatchDTO) => {
    if (
      jobMatch.last_role_at_org === jobMatch.current_job_title &&
      jobMatch.current_company === orgName
    ) {
      return "";
    }
    return jobMatch.current_company;
  };

  const COLUMN_WIDTHS = {
    name: 25,
    topAttribute: 15,
    status: 10,
    emailsSent: 7,
    formerRole: 15,
    formerSalary: 10,
    lastKnownRole: 15,
    lastKnownLocation: 15,
    singleDropDown: 5,
  };

  const calculateMinTableWidth = () => {
    let minTableWidth = 0;
    Object.values(COLUMN_WIDTHS).forEach((width) => {
      minTableWidth += width;
    });

    if (!userConfig?.hasSalaryData()) {
      minTableWidth -= COLUMN_WIDTHS.formerSalary;
    }

    if (!showPostDepartureDataRelatedFields()) {
      minTableWidth -= COLUMN_WIDTHS.lastKnownRole;
      minTableWidth -= COLUMN_WIDTHS.lastKnownLocation;
    }

    return minTableWidth;
  };

  const minTableWidth = calculateMinTableWidth();

  const STICKY_COLUMN_STYLES = {
    position: "sticky",
    background: "inherit",
    zIndex: 1,
  };

  const nameColumnStyle = {
    ...STICKY_COLUMN_STYLES,
    width: `calc(${COLUMN_WIDTHS.name} / ${minTableWidth} * 100%)`,
    left: 0,
    borderRight: `1px solid ${theme.palette.grey[200]}`,
  };

  const singleDropDownColumnStyle = {
    ...STICKY_COLUMN_STYLES,
    width: `calc(${COLUMN_WIDTHS.singleDropDown} / ${minTableWidth} * 100%)`,
    right: 0,
    borderLeft: `1px solid ${theme.palette.grey[200]}`,
    borderRight: `1px solid ${theme.palette.grey[200]}`,
    alignItems: "center",
  };

  const columns: TableColumn<JobAlumniMatchDTO>[] = [
    {
      id: "name",
      label: () => (
        <Stack gap="1rem" alignItems="center" direction="row">
          <Stack height="1.5rem" justifyContent="center">
            <Tooltip
              title={
                selectedIds.size == 0 ? t("table.selectAllAlumni.label") : ""
              }
            >
              <div>
                <Checkbox
                  checked={
                    allCheckedOnPage
                      ? CheckedState.CHECKED
                      : someCheckedOnPage
                      ? CheckedState.MIXED
                      : CheckedState.UNCHECKED
                  }
                  onCheck={checkOrUncheckOnPage}
                  disabled={isRoleClosed}
                />
              </div>
            </Tooltip>
          </Stack>

          <Typography
            fontSize="0.75rem"
            fontWeight={500}
            lineHeight="1.125rem"
            color={(theme) => theme.palette.grey[500]}
          >
            {t("table.name.label")}
          </Typography>
        </Stack>
      ),
      content: (jobMatch: JobAlumniMatchDTO) => {
        const isHired = jobMatch.status === JobMatchStatus.HIRED;
        return (
          <Stack gap="0.75rem" alignItems="center" direction="row">
            <Stack height="3rem" justifyContent="center">
              <Checkbox
                id={"checkbox"}
                checked={
                  selectedIds.has(jobMatch.applicant_id)
                    ? CheckedState.CHECKED
                    : CheckedState.UNCHECKED
                }
                onCheck={() => checkOrUncheckRow(jobMatch.applicant_id)}
                disabled={
                  isRoleClosed ||
                  !jobMatch.is_subscribed ||
                  jobMatch.status === JobMatchStatus.HIRED
                }
              />
            </Stack>

            {tabValue !== 2 &&
              <Tooltip
                title={
                  jobMatch.state === MatchedApplicantState.BOOKMARK
                    ? t("table.bookmarkIcon.removeBookmark")
                    : t("table.bookmarkIcon.bookmark")
                }
              >
                <Box
                  component="img"
                  src={
                    jobMatch.state === MatchedApplicantState.BOOKMARK
                      ? BookmarkFilledPurpleIcon
                      : BookmarkFilledGreyIcon
                  }
                  onClick={() => {
                    if (jobMatch.state === MatchedApplicantState.BOOKMARK) {
                      onAlumniRemoveBookmark(jobMatch);
                    } else {
                      onAlumniBookmark(jobMatch);
                    }
                  }}
                  sx={{
                    color: theme.palette.primary.main,
                    cursor: "pointer",
                    "&:hover": {
                      opacity: 0.8,
                    },
                  }}
                />
              </Tooltip>
            }

            <Tooltip title={isHired ? t("table.hiredTooltip") : ""}>
              <Box
                sx={{ cursor: isHired ? "auto" : "pointer" }}
                onClick={() => !isHired && onClick(jobMatch.applicant_id)}
                minWidth="19rem"
              >
                <Avatar
                  firstName={jobMatch.first_name}
                  lastName={jobMatch.last_name}
                  email={jobMatch.email}
                  size={AvatarSize.MEDIUM}
                  photoUrl={jobMatch.profile_photo_url}
                  isSubscribed={jobMatch.is_subscribed}
                  tag={jobMatch.is_new ? <NewTag /> : null}
                  isDisabled={isHired}
                  showAlumState={showAlumniActions()}
                />
              </Box>
            </Tooltip>
          </Stack>
        );
      },
      styles: nameColumnStyle,
    },
    {
      id: "topAttribute",
      label: () => (
        <Typography
          fontSize="0.75rem"
          fontWeight={500}
          lineHeight="1.125rem"
          color={(theme) => theme.palette.grey[500]}
        >
          {t("table.topAttribute.label")}
        </Typography>
      ),
      content: (jobMatch: JobAlumniMatchDTO) => (
        <MatchAttributePill
          attribute={jobMatch.notable_feature}
          attributeType={jobMatch.notable_feature_tag as MatchAttributeType}
          fontSize="0.75rem"
          lineHeight="1.125rem"
          isDisabled={jobMatch.status === JobMatchStatus.HIRED}
        />
      ),
      styles: {
        width: `calc(${COLUMN_WIDTHS.topAttribute} / ${minTableWidth} * 100%)`,
      },
    },
    {
      id: "status",
      label: () => (
        <Typography
          fontSize="0.75rem"
          fontWeight={500}
          lineHeight="1.125rem"
          color={(theme) => theme.palette.grey[500]}
        >
          {t("table.status.label")}
        </Typography>
      ),
      content: (jobMatch: JobAlumniMatchDTO) => (
        <Stack>
          <FormattedText
            props={{
              value: getJobMatchStatusString(jobMatch.status, t),
              ellipsis: false,
            }}
            style={{
              sx: (theme) => {
                if (jobMatch.status === JobMatchStatus.HIRED) {
                  return {
                    width: "max-content",
                    fontSize: "0.75rem",
                    marginBottom: "0.25rem",
                    padding: "2px 6px",
                    borderRadius: "6px",
                    color: theme.palette.primary.main,
                    background: theme.palette.primary.light,
                    border: `1px solid ${theme.palette.custom.purpleBorder}`,
                  };
                }
                return {};
              },
            }}
          />
          <FormattedText
            props={{
              value: jobMatch.status_modified_date,
              format: Format.dateDayMonthYear,
              ellipsis: false,
              type: FormattedTextType.SECONDARY,
            }}
          />
        </Stack>
      ),
      styles: {
        width: `calc(${COLUMN_WIDTHS.status} / ${minTableWidth} * 100%)`,
      },
    },
    {
      id: "emailsSent",
      label: () => (
        <Typography
          fontSize="0.75rem"
          fontWeight={500}
          lineHeight="1.125rem"
          color={(theme) => theme.palette.grey[500]}
        >
          {t("table.emailsSent.label")}
        </Typography>
      ),
      content: (jobMatch: JobAlumniMatchDTO) => (
        <Box
          width="max-content"
          marginLeft="auto"
          color={(theme) => {
            if (
              jobMatch.status === JobMatchStatus.HIRED ||
              jobMatch.num_emails_sent === 0
            ) {
              return theme.palette.grey[400];
            }
            return theme.palette.grey[600];
          }}
        >
          {jobMatch.num_emails_sent}
        </Box>
      ),
      styles: {
        width: `calc(${COLUMN_WIDTHS.emailsSent} / ${minTableWidth} * 100%)`,
      },
    },
    {
      id: "formerRole",
      label: () => (
        <Typography
          fontSize="0.75rem"
          fontWeight={500}
          lineHeight="1.125rem"
          color={(theme) => theme.palette.grey[500]}
        >
          {t("table.formerRole.label", { org: orgName })}
        </Typography>
      ),
      content: (jobMatch: JobAlumniMatchDTO) => (
        <Stack>
          <FormattedText
            props={{
              value: jobMatch.last_role_at_org,
              format: Format.uppercaseFirstLetter,
            }}
            style={{
              color: (theme) =>
                jobMatch.status === JobMatchStatus.HIRED
                  ? theme.palette.grey[400]
                  : theme.palette.grey[800],
            }}
          />
          <FormattedText
            props={{
              value: jobMatch.last_role_at_org_department,
              format: Format.uppercaseFirstLetter,
              type: FormattedTextType.SECONDARY,
            }}
            style={{
              color: (theme) =>
                jobMatch.status === JobMatchStatus.HIRED
                  ? theme.palette.grey[400]
                  : theme.palette.grey[500],
            }}
          />
        </Stack>
      ),
      styles: {
        width: `calc(${COLUMN_WIDTHS.formerRole} / ${minTableWidth} * 100%)`,
      },
    },
    ...(userConfig?.hasSalaryData()
      ? [
          {
            id: "formerSalary",
            label: () => (
              <Typography
                fontSize="0.75rem"
                fontWeight={500}
                lineHeight="1.125rem"
                color={(theme) => theme.palette.grey[500]}
              >
                {t("table.formerSalary.label")}
              </Typography>
            ),
            content: (jobMatch: JobAlumniMatchDTO) => {
              const payRate = jobMatch.former_salary;
              const payPeriod = jobMatch.former_salary_pay_period;
              return <SalaryText payRate={payRate} payPeriod={payPeriod} />;
            },
            styles: {
              width: `calc(${COLUMN_WIDTHS.formerSalary} / ${minTableWidth} * 100%)`,
            },
          },
        ]
      : []),
    ...(showPostDepartureDataRelatedFields()
      ? [
          {
            id: "lastKnownRole",
            label: () => (
              <Typography
                fontSize="0.75rem"
                fontWeight={500}
                lineHeight="1.125rem"
                color={(theme) => theme.palette.grey[500]}
              >
                {t("table.lastKnownRole.label")}
              </Typography>
            ),
            content: (jobMatch: JobAlumniMatchDTO) => (
              <Stack>
                <FormattedText
                  props={{
                    value: renderCorrectCurrentJobTitleText(jobMatch),
                    format: Format.uppercaseFirstLetter,
                  }}
                  style={{
                    color: (theme) =>
                      jobMatch.status === JobMatchStatus.HIRED
                        ? theme.palette.grey[400]
                        : theme.palette.grey[800],
                  }}
                />
                <FormattedText
                  props={{
                    value: renderCorrectCurrentCompanyText(jobMatch),
                    format: Format.uppercaseFirstLetter,
                    type: FormattedTextType.SECONDARY,
                  }}
                  style={{
                    color: (theme) =>
                      jobMatch.status === JobMatchStatus.HIRED
                        ? theme.palette.grey[400]
                        : theme.palette.grey[500],
                  }}
                />
              </Stack>
            ),
            styles: {
              width: `calc(${COLUMN_WIDTHS.lastKnownRole} / ${minTableWidth} * 100%)`,
            },
          },
          {
            id: "lastKnownLocation",
            label: () => (
              <Typography
                fontSize="0.75rem"
                fontWeight={500}
                lineHeight="1.125rem"
                color={(theme) => theme.palette.grey[500]}
              >
                {t("table.lastKnownLocation.label")}
              </Typography>
            ),
            content: (jobMatch: JobAlumniMatchDTO) => (
              <FormattedText
                props={{
                  value: jobMatch.last_known_location,
                  format: Format.location,
                }}
              />
            ),
            styles: {
              width: `calc(${COLUMN_WIDTHS.lastKnownLocation} / ${minTableWidth} * 100%)`,
            },
          },
        ]
      : []),
    {
      id: "singleDropDown",
      label: () => <></>,
      content: (jobMatch: JobAlumniMatchDTO) => (
        <Menu
          className="row-dropdown-opener"
          items={generateMenuOptions(jobMatch)}
        />
      ),
      styles: singleDropDownColumnStyle,
    },
  ];

  return (
    <Table
      columns={columns}
      rows={paginatedJobMatches}
      page={page}
      pageSize={pageSize}
      totalRows={totalRows}
      onPageChange={onPageChange}
      minWidth={minTableWidth + "rem"}
      categoryPlural={t("paginationFooter.alumni")}
      categorySingular={t("paginationFooter.alum")}
    />
  );
}
