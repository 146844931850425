import { useTranslation } from "react-i18next";
import { useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";
import Input from "../../theme/Input";
import { getSignInMethod, SignInMethod, SignInRequest } from "../../api/auth";
import "../../CustomToastAnimations.css";
import { OIDC_SIGNIN_REDIRECT_URL, Translation } from "../../lib/constants";
import theme from "../../theme";
import MuiButton from "@mui/material/Button";
import { setOidcClientConfigInStorage, validateEmail } from "../../lib/utils";
import ErrorMessage from "../../theme/ErrorMessage";
import { UserManager } from "oidc-client-ts";

type props = {
  showPasswordInput: () => void;
  loginData: SignInRequest;
  setLoginData: (data: SignInRequest) => void;
}

export default function EmailForm({
  showPasswordInput,
  loginData,
  setLoginData,
}: props) {
  const { t } = useTranslation(Translation.login);
  const [emailError, setEmailError] = useState("");
  const [submitError, setSubmitError] = useState("");
  const [loading, setLoading] = useState(false);

  const validateForm = (): boolean => {
    if (loginData.email.length === 0) {
      setEmailError(t("emailRequired"));
      return false
    } else if (!validateEmail(loginData.email)) {
      setEmailError(t("invalidEmail"));
      return false
    }

    return true;
  }
  

  const lookUpSignInMethod = async () => {
    if (!validateForm()) return;

    try {
      setLoading(true);
      const response = await getSignInMethod(loginData.email);

      if (response.type === "EMAIL_PASSWORD") {
        showPasswordInput();
      } else if (response.type === "OIDC_SSO") {
        handleOidcSignIn(response);
      } else if (response.type === "SAML_SSO") {
        handleSamlSignIn(response);
      } else {
        throw new Error("Invalid sign in method");
      }
    } catch (error: any) {
      setSubmitError(t("error.unexpected", { ns: Translation.common }));
      setLoading(false);
    }
  };

  const handleOidcSignIn = async (signInMethod: SignInMethod) => {
    if (!signInMethod.authority || !signInMethod.client_id || !signInMethod.scopes) {
      throw new Error("Invalid sign in method");
    }

    const userManagerConfig = {
      authority: signInMethod.authority,
      client_id: signInMethod.client_id,
      redirect_uri: OIDC_SIGNIN_REDIRECT_URL,
      scope: signInMethod.scopes,
    }
    // we set these values in local storage because the OAuth Callback component needs them
    // to reinitialize the UserManager to complete the OAuth sign in process
    setOidcClientConfigInStorage(userManagerConfig);

    const OAuthUserManager = new UserManager(userManagerConfig);

    OAuthUserManager.signinRedirect().catch((error) => {
      console.error("Error redirecting to Identity provider", error);
    });
  };

  const handleSamlSignIn = async (signInMethod: SignInMethod) => {
    if (!signInMethod.redirect_url) {
      throw new Error("Invalid sign in method");
    }

    window.location.href = signInMethod.redirect_url;
  };

  return (
    <>
      {submitError !== "" && <ErrorMessage error={submitError} />}
      
    <Stack width="100%">
      <Input
        label={t("email")}
        placeholder={t("emailPlaceholder")}
        onChange={(event) => { setLoginData({ ...loginData, email: event.target.value }) }}
        onKeyUp={(event) => { if (event.key === "Enter") lookUpSignInMethod() }}
        error={emailError}
        stackProps={{ height: "6.5rem" }}
        inputProps={{ name: "email" }}
      />

      <MuiButton
        variant="contained"
        disabled={loading}
        id="email-form-next-button"
        sx={{
          height: "2.5rem",
          backgroundColor: theme.palette.primary.main,
          borderRadius: "0.5rem",
          boxShadow: "none",
          "&:hover": {
            boxShadow: "none",
          },
          "&:disabled": {
            backgroundColor: theme.palette.custom.purpleDisabled,
            color: theme.palette.common.white,
          },
          transition: "none",
        }}
        onClick={lookUpSignInMethod}
      >
        {loading ? (
          <CircularProgress size="1rem" />
        ) : (
          <Stack gap="0.5rem" direction="row" alignItems="center">
            {t("next")}
          </Stack>
        )}
      </MuiButton>
    </Stack>

    </>
  );
}
