import { JobDashboardDTO } from "../../models/api/job";
import { Stack, Box, Typography, Tooltip } from "@mui/material";
import OrgChartIcon from "/org-chart.svg";
import UserIcon from "/user.svg";
import LocationMarkerIcon from "/location-marker.svg";
import CalendarIcon from "/calendar.svg";
import { useTranslation } from "react-i18next";
import { Translation } from "../../lib/constants";
import { useContext } from "react";
import { UserConfigContext } from "../../lib/context";
import { FormattedText, FormattedTextType } from "../../theme/FormattedText";
import { Format } from "../../lib/utils";

interface RolesViewOverviewProps {
  job: JobDashboardDTO;
}

interface OverviewInfoLabelProps {
  id: string;
  contents: string[];
  icon: string;
  format: (value: string) => string;
}

interface OverviewCountBoxProps {
  title: string;
  count: number | null;
}

function OverviewInfoLabel(props: OverviewInfoLabelProps) {
  const { id, contents, icon, format } = props;
  const { t } = useTranslation(Translation.rolesView);

  return (
    <Stack id={id} direction="row" alignItems="center" spacing={0.5}>
      <Box component="img" src={icon} />
      <FormattedText
        props={{
          value: contents[0] ?? t("unknown"),
          format: contents[0] ? format : Format.uppercaseFirstLetter,
          type: FormattedTextType.SECONDARY,
        }}
      />
      {contents.length > 1 && (
        <Tooltip
          title={
            <>
              {contents.slice(1).map((content, idx) => (
                <Stack
                  direction="row"
                  gap="0.25rem"
                  alignItems="center"
                  key={idx}
                >
                  <Box component="img" src={icon} />
                  <FormattedText
                    props={{
                      value: content,
                      format: format,
                      type: FormattedTextType.SECONDARY,
                    }}
                    style={{
                      fontSize: "0.75rem",
                      lineHeight: "1.125rem",
                      color: "inherit",
                    }}
                  />
                </Stack>
              ))}
            </>
          }
        >
          <Typography
            sx={{ background: (theme) => theme.palette.grey[50] }}
            alignItems="center"
            fontSize="0.75rem"
            fontWeight={500}
            lineHeight="1.125rem"
            borderRadius="2rem"
            padding="0.125rem 0.5rem"
          >
            +{contents.length - 1}
          </Typography>
        </Tooltip>
      )}
    </Stack>
  );
}

function OverviewCountBox(props: OverviewCountBoxProps) {
  const { title, count } = props;
  const { t } = useTranslation(Translation.common);
  return (
    <Box
      borderRadius="0.75rem"
      border={(theme) => `1px solid ${theme.palette.divider}`}
      padding="1rem"
      width="33%"
    >
      <Stack direction="column" alignItems="left" spacing={0.5}>
        <Typography
          color={(theme) => theme.palette.grey[500]}
          fontSize="0.75rem"
          fontWeight={500}
          lineHeight="1.5rem"
        >
          {title}
        </Typography>
        {count !== null ? (
          <Typography
            color={(theme) => theme.palette.grey[800]}
            fontSize="1.25rem"
            fontWeight={500}
            lineHeight="2.5rem"
          >
            {count}
          </Typography>
        ) : (
          <Typography
            color={(theme) => theme.palette.grey[400]}
            fontSize="0.75rem"
            fontWeight={400}
            lineHeight="2.5rem"
          >
            {t("dataUnavailable")}
          </Typography>
        )}
      </Stack>
    </Box>
  );
}

export default function RolesViewOverview(props: RolesViewOverviewProps) {
  const { job } = props;
  const { t } = useTranslation(Translation.rolesView);
  const [userConfig] = useContext(UserConfigContext);

  return (
    <Box>
      <Stack direction="row" alignItems="center" paddingTop="1rem" spacing={3}>
        <OverviewInfoLabel
          id="departments"
          contents={job.departments}
          icon={OrgChartIcon}
          format={Format.uppercaseFirstLetter}
        ></OverviewInfoLabel>
        <OverviewInfoLabel
          id="locations"
          contents={job.locations}
          icon={LocationMarkerIcon}
          format={Format.location}
        ></OverviewInfoLabel>
        {(userConfig == null || !userConfig.isAtsScraperIntegrationOnly()) && (
          <OverviewInfoLabel
            id="recruiters"
            contents={job.recruiters.map(
              (recruiter) => `${recruiter.first_name} ${recruiter.last_name}`,
            )}
            icon={UserIcon}
            format={Format.uppercaseFirstLetter}
          ></OverviewInfoLabel>
        )}
        <OverviewInfoLabel
          id="datePosted"
          contents={job.date_posted ? [job.date_posted] : []}
          icon={CalendarIcon}
          format={Format.dateLongDayMonthYear}
        ></OverviewInfoLabel>
      </Stack>
      <Stack
        direction="row"
        alignItems="center"
        padding="2rem 0rem"
        spacing={2}
      >
        <OverviewCountBox
          title={t("alumniMatches")}
          count={job.num_matches}
        ></OverviewCountBox>
        <OverviewCountBox
          title={t("overview.alumniApplications")}
          count={job.num_applications}
        ></OverviewCountBox>
        <OverviewCountBox
          title={t("overview.daysOpen")}
          count={job.days_open}
        ></OverviewCountBox>
      </Stack>
    </Box>
  );
}
