export type UserConfigJson = {
  org_config: OrgConfigJson;
};

export type OrgConfigJson = {
  ats_integration_data_sources: string[];
  has_alum_hiring_preferences_data: boolean;
  has_salary_data: boolean;
  alum_employment_types: string[];
  has_jobs_with_no_department: boolean;
  has_automated_job_matching_enabled: boolean;
  has_managerial_data: boolean;
};

export class UserConfigDTO {
  org: OrgConfigDTO;

  constructor(org: OrgConfigDTO) {
    this.org = org;
  }

  isAtsScraperIntegrationOnly(): boolean {
    var atsIntegrationDataSources = this.org.atsIntegrationDataSources;
    return (
      atsIntegrationDataSources.length === 1 &&
      atsIntegrationDataSources[0] === AtsIntegrationType.SCRAPER
    );
  }

  hasAlumHiringPreferencesData(): boolean {
    return this.org.hasAlumHiringPreferencesData;
  }

  hasSalaryData(): boolean {
    return this.org.hasSalaryData;
  }

  hasJobsWithNoDepartment(): boolean {
    return this.org.hasJobsWithNoDepartment;
  }

  hasAutomatedJobMatchingEnabled(): boolean {
    return this.org.hasAutomatedJobMatchingEnabled;
  }

  static fromJson(json: UserConfigJson): UserConfigDTO {
    return new UserConfigDTO(OrgConfigDTO.fromJson(json.org_config));
  }
}

class OrgConfigDTO {
  atsIntegrationDataSources: string[];
  hasAlumHiringPreferencesData: boolean;
  hasSalaryData: boolean;
  alumEmploymentTypes: string[];
  hasJobsWithNoDepartment: boolean;
  hasAutomatedJobMatchingEnabled: boolean;
  hasManagerialData: boolean;

  constructor(
    atsIntegrationDataSources: string[],
    hasAlumHiringPreferencesData: boolean,
    hasSalaryData: boolean,
    alumEmploymentTypes: string[],
    hasJobsWithNoDepartment: boolean,
    hasAutomatedJobMatchingEnabled: boolean,
    hasManagerialData: boolean,
  ) {
    this.atsIntegrationDataSources = atsIntegrationDataSources;
    this.hasAlumHiringPreferencesData = hasAlumHiringPreferencesData;
    this.hasSalaryData = hasSalaryData;
    this.alumEmploymentTypes = alumEmploymentTypes;
    this.hasJobsWithNoDepartment = hasJobsWithNoDepartment;
    this.hasAutomatedJobMatchingEnabled = hasAutomatedJobMatchingEnabled;
    this.hasManagerialData = hasManagerialData;
  }

  static fromJson(json: OrgConfigJson): OrgConfigDTO {
    return new OrgConfigDTO(
      json.ats_integration_data_sources,
      json.has_alum_hiring_preferences_data,
      json.has_salary_data,
      json.alum_employment_types,
      json.has_jobs_with_no_department,
      json.has_automated_job_matching_enabled,
      json.has_managerial_data,
    );
  }
}

enum AtsIntegrationType {
  SCRAPER = "SCRAPER",
  MERGE = "MERGE",
}
