import {
  cssTransition,
  ToastContainer,
  ToastContainerProps,
} from "react-toastify";
import theme from "../../theme";
import { Translation } from "../../lib/constants.ts";
import { useTranslation } from "react-i18next";
import checkCircleIcon from "/check-circle.svg";

const toastTransition = cssTransition({
  enter: "boom_custom_toast_fade_in",
  exit: "boom_custom_toast_fade_out",
});

interface SuccessToastContainerProps extends ToastContainerProps {}

export default function SuccessToastContainer(
  props: SuccessToastContainerProps,
) {
  const { t } = useTranslation(Translation.alumProfile);
  return (
    <ToastContainer
      containerId={props.containerId}
      position={props.position || "top-center"}
      style={props.style || { width: "fit-content" }}
      autoClose={5000}
      hideProgressBar
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable={false}
      pauseOnHover
      transition={toastTransition}
      closeButton={false}
      toastStyle={{
        backgroundColor: theme.palette.notification.success.backgroundColor,
        color: theme.palette.notification.success.color,
        fontWeight: "600",
        fontFamily: "Inter",
        fontSize: "14px",
        borderRadius: "4px",
        borderColor: theme.palette.notification.success.borderColor,
        border: "1px solid",
        minHeight: "52px",
        boxShadow: "none",
      }}
      icon={
        <img
          src={checkCircleIcon}
          alt={t("checkCircleIconAlt", { ns: Translation.common })}
        />
      }
      role="status"
      {...props}
    />
  );
}
