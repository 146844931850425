import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import theme from "../../theme";
import { ReactNode } from "react";

type props = {
  icon: string;
  iconAlt: string;
  title: string;
  message: string | ReactNode;
};

export default function EmptyList({ icon, iconAlt, title, message }: props) {
  return (
    <Stack direction="column" justifyContent="center" alignItems="center" className="emptyList">
      <Box
        padding="8px"
        borderRadius="50%"
        overflow="hidden"
        display="grid"
        sx={{
          placeItems: "center",
          backgroundColor: theme.palette.grey[100],
        }}
      >
        <Box
          component="img"
          alt={iconAlt}
          src={icon}
          width="24px"
          height="24px"
        />
      </Box>
      <Typography
        style={{
          paddingBottom: "0.125rem",
          color: theme.palette.grey[600],
          fontSize: "1rem",
          fontWeight: 600,
          paddingTop: "1rem",
        }}
      >
        {title}
      </Typography>
      <Typography
        style={{
          color: theme.palette.grey[500],
          fontSize: "0.875rem",
          fontWeight: 400,
          paddingTop: "0.125rem",
          whiteSpace: "pre-line"
        }}
        align="center"
      >
        {message}
      </Typography>
    </Stack>
  );
}
