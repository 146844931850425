import { Stack } from "@mui/material";
import { FormattedText, FormattedTextType } from "../../theme/FormattedText";
import { formatAnnuPay, formatPayRate } from "../../lib/utils";
import { PayPeriodEnum } from "../../models/api/job";
import { useTranslation } from "react-i18next";
import { Translation } from "../../lib/constants";

interface SalaryText {
  payRate: number;
  payPeriod: string | null;
}

export default function SalaryText(props: SalaryText) {
  const { payRate, payPeriod } = props;
  const { i18n } = useTranslation(Translation.alumniDatabase);
  const common = useTranslation(Translation.common)[0];

  if (payRate === 0 || payPeriod == null) return <></>;

  return (
    <Stack>
      <FormattedText
        props={{
          value: formatPayRate(payRate, payPeriod, common, i18n.language),
        }}
        style={{
          align: "right",
        }}
      />
      {payPeriod !== PayPeriodEnum.YEAR.toString() && (
        <FormattedText
          props={{
            value: formatAnnuPay(payRate, payPeriod, common, i18n.language),
            type: FormattedTextType.SECONDARY,
          }}
          style={{
            align: "right",
          }}
        />
      )}
    </Stack>
  );
}
