import { http } from ".";
import {
  AlumProfileAutocompleteResponse,
  AlumProfileAutocompleteByNameResponse,
  AlumDTO,
  ListAlumniResponse,
} from "../models/api/alum";
import { HttpError, HttpResponse, ResponseStatus } from "../models/api/http";
import { AlumniDbFilterOptions } from "../models/filter";

export const getAlum = async (
  orgId: string,
  alumId: string,
): Promise<AlumDTO> => {
  const response = await http.get<HttpResponse<AlumDTO>>(
    `/api/org/${orgId}/profile/alumni/${alumId}?federate_employments=true`,
  );
  if (response.data.status === ResponseStatus.ERROR || !response.data.data) {
    throw new HttpError(response.status, response.data.message ?? "");
  }
  return response.data.data;
};

export const listAlumni = async (
  orgId: string,
  page: number,
  pageSize: number,
  sortOption: string,
  sortDesc: boolean,
  searchTerm: string,
  filters: AlumniDbFilterOptions,
): Promise<ListAlumniResponse> => {
  const response = await http.post<HttpResponse<ListAlumniResponse>>(
    `/api/org/${orgId}/profile/alumni/list?page=${page}&page_size=${pageSize}&sort_option=${sortOption}&sort_desc=${sortDesc}&search_term=${encodeURIComponent(
      searchTerm,
    )}`,
    transformFiltersForBackend(filters),
  );
  if (response.data.status === ResponseStatus.ERROR || !response.data.data) {
    throw new HttpError(response.status, response.data.message ?? "");
  }
  return response.data.data;
};

export const autocompleteAlumniDatabaseSearch = async (
  orgId: string,
  searchTerm: string,
  limit: number,
): Promise<AlumProfileAutocompleteResponse> => {
  const response = await http.get<
    HttpResponse<AlumProfileAutocompleteResponse>
  >(
    `/api/org/${orgId}/profile/alumni/autocomplete?search_term=${encodeURIComponent(
      searchTerm,
    )}&limit=${limit}`,
  );
  if (response.data.status === ResponseStatus.ERROR || !response.data.data) {
    throw new HttpError(response.status, response.data.message ?? "");
  }
  return response.data.data;
};

export const autocompleteAlumniDatabaseSearchByName = async (
  orgId: string,
  searchTerm: string,
  limit: number,
): Promise<AlumProfileAutocompleteByNameResponse> => {
  const response = await http.get<
    HttpResponse<AlumProfileAutocompleteByNameResponse>
  >(
    `/api/org/${orgId}/profile/alumni/name/autocomplete?search_term=${encodeURIComponent(
      searchTerm,
    )}&limit=${limit}`,
  );
  if (response.data.status === ResponseStatus.ERROR || !response.data.data) {
    throw new HttpError(response.status, response.data.message ?? "");
  }
  return response.data.data;
};

const transformFiltersForBackend = (filters: AlumniDbFilterOptions) => {
  return {
    last_known_locations: Array.from(filters.last_known_locations),
    former_departments: Array.from(filters.former_departments),
    former_employment_types: Array.from(filters.former_employment_types).reduce(
      (acc: string[], [key, value]) => (value ? acc.concat(key) : acc),
      [],
    ),
    former_salary: {
      min: filters.former_salary.min == "" ? null : +filters.former_salary.min,
      max: filters.former_salary.max == "" ? null : +filters.former_salary.max,
    },
    tenure: {
      min: filters.tenure.min == "" ? null : +filters.tenure.min,
      max: filters.tenure.max == "" ? null : +filters.tenure.max,
    },
    time_since_departure: filters.time_since_departure,
    state: filters.state
  };
};
export const archive = async (
  orgId: string,
  employeeIds: string[],
): Promise<void> => {
  const response = await http.patch<HttpResponse<void>>(
    `/api/org/${orgId}/alumni-matches/archive`,
    { employee_ids: employeeIds },
  );
  if (response.data.status === ResponseStatus.ERROR) {
    throw new HttpError(response.status, response.data.message ?? "");
  }
};

export const restore = async (
  orgId: string,
  employeeIds: string[],
): Promise<void> => {
  const response = await http.patch<HttpResponse<void>>(
    `/api/org/${orgId}/alumni-matches/restore-alum`,
    { employee_ids: employeeIds },
  );
  if (response.data.status === ResponseStatus.ERROR) {
    throw new HttpError(response.status, response.data.message ?? "");
  }
};

export const favorite = async (
  orgId: string,
  employeeIds: string[],
): Promise<void> => {
  const response = await http.patch<HttpResponse<void>>(
    `/api/org/${orgId}/alumni-matches/favorite`,
    { employee_ids: employeeIds },
  );
  if (response.data.status === ResponseStatus.ERROR) {
    throw new HttpError(response.status, response.data.message ?? "");
  }
};

export const removeFavorite = async (
  orgId: string,
  employeeIds: string[],
): Promise<void> => {
  const response = await http.patch<HttpResponse<void>>(
    `/api/org/${orgId}/alumni-matches/remove-favorite`,
    { employee_ids: employeeIds },
  );
  if (response.data.status === ResponseStatus.ERROR) {
    throw new HttpError(response.status, response.data.message ?? "");
  }
};
