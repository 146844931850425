import { ReactNode, useState } from "react";
import {
  UserConfigContext,
  FlagsContext,
  UserContext,
  OidcUserContext
} from "../../lib/context";
import { AuthUser } from "../../models/auth";
import { loadUserFromStorage } from "../../lib/utils";
import { UserConfigDTO } from "../../models/config";
import { UserManager } from "oidc-client-ts";

interface ContextProviderProps {
  children: ReactNode;
}

export default function ContextProvider(props: ContextProviderProps) {
  const userContext = useState<AuthUser | null>(loadUserFromStorage());
  const oidcUserContext = useState<UserManager | null>(null);
  const userConfigContext = useState<UserConfigDTO | null>(null);
  const flagsContext = useState<string[] | null>(null);

  return (
    <UserContext.Provider value={userContext}>
      <OidcUserContext.Provider value={oidcUserContext}>
        <UserConfigContext.Provider value={userConfigContext}>
          <FlagsContext.Provider value={flagsContext}>
            {props.children}
          </FlagsContext.Provider>
        </UserConfigContext.Provider>
      </OidcUserContext.Provider>
    </UserContext.Provider>
  );
}
