import { NameDTO } from "./common";
import { JobMatchStatus, MatchedApplicantState } from "../job.ts";
import { JobStatusEnum } from "../filter.ts";

export type JobDashboardResponse = {
  jobs: JobDashboardDTO[];
  total: number;
};

export type JobDashboardDTO = {
  id: string;
  name: string;
  requisition_id: string;
  departments: string[];
  recruiters: NameDTO[];
  locations: string[];
  date_posted: string | null;
  status: JobStatusEnum;
  days_open: number | null;
  num_matches: number;
  num_applications: number;
  boomerang_hire: boolean;
  job_posting_urls?: AtsJobPostingUrl[];
  career_site_url: string;
  time_of_last_matching_run: string | null;
  criteria_name: string;
  criteria_id: string | null;
  is_criteria_automated: boolean | null;
};

export type AtsJobPostingUrl = {
  modifiedByDataSourceAt?: string;
  url: string;
  type: string;
};

export type JobDashboardMatchDTO = {
  employee: JobDashboardEmployeeDTO;
  stage: string | null;
  stage_order: number | null;
  rejected_at: string | null;
  reject_reason: string | null;
  has_applied: boolean;
};

export type JobDashboardEmployeeDTO = {
  id: string;
  email: string;
  first_name: string;
  last_name: string;
};

export type ListJobAlumniMatchDTO = {
  alumni_matches: JobAlumniMatchDTO[];
  total: number;
};

export type JobAlumniMatchDTO = {
  applicant_id: string;
  first_name: string;
  last_name: string;
  profile_photo_url: string | null;
  email: string;
  org_name: string;
  current_job_title: string | null;
  current_company: string | null;
  last_role_at_org: string;
  last_role_at_org_department: string;
  notable_feature: string;
  notable_feature_tag: string;
  status: JobMatchStatus;
  status_modified_date: string;
  num_emails_sent: number;
  is_subscribed: boolean;
  is_new: boolean;
  last_known_location: string;
  former_salary: number;
  former_salary_pay_period: string | null;
  state: MatchedApplicantState | null;
  is_favorite: boolean;
};

export type JobDTO = {
  id: string;
  name: string;
  status: string;
  posted_date: Date | null;
  requisition_id: string;
};

export type JobAutocompleteResponse = {
  suggestions: string[];
};

export type JobTitleDTO = {
  title: string;
  role_matching_criteria_id: string;
};

export type ListJobTitlesByDepartmentResponse = {
  job_titles: JobTitleDTO[];
};

export enum RoleViewSortOptionEnum {
  POSTED_DATE = "POSTED_DATE",
  JOB_NAME = "JOB_NAME",
  NUM_MATCHED = "NUM_MATCHED",
  NUM_APPLICATIONS = "NUM_APPLICATIONS",
}

export enum AlumniMatchesSortOptionEnum {
  MATCH_STRENGTH = "MATCH_STRENGTH",
  STATUS_MODIFIED_DATE = "STATUS_MODIFIED_DATE",
  NAME = "NAME",
}

export enum PayPeriodEnum {
  HOUR = "HOUR",
  DAY = "DAY",
  WEEK = "WEEK",
  EVERY_TWO_WEEKS = "EVERY_TWO_WEEKS",
  SEMIMONTHLY = "SEMIMONTHLY",
  MONTH = "MONTH",
  QUARTER = "QUARTER",
  EVERY_SIX_MONTHS = "EVERY_SIX_MONTHS",
  YEAR = "YEAR",
}

export enum MatchedApplicantStateEnum {
  UNMATCH = "UNMATCH",
  BOOKMARK = "BOOKMARK",
}

export const PayPeriodYearConversion = {
  [PayPeriodEnum.HOUR]: 2080,
  [PayPeriodEnum.DAY]: 260,
  [PayPeriodEnum.WEEK]: 52,
  [PayPeriodEnum.EVERY_TWO_WEEKS]: 26,
  [PayPeriodEnum.SEMIMONTHLY]: 24,
  [PayPeriodEnum.MONTH]: 12,
  [PayPeriodEnum.QUARTER]: 4,
  [PayPeriodEnum.EVERY_SIX_MONTHS]: 2,
  [PayPeriodEnum.YEAR]: 1,
};

export const PayPeriodSuffix = {
  [PayPeriodEnum.HOUR]: "payPeriod.hour",
  [PayPeriodEnum.DAY]: "payPeriod.day",
  [PayPeriodEnum.WEEK]: "payPeriod.week",
  [PayPeriodEnum.EVERY_TWO_WEEKS]: "payPeriod.everyTwoWeeks",
  [PayPeriodEnum.SEMIMONTHLY]: "payPeriod.semiMonthly",
  [PayPeriodEnum.MONTH]: "payPeriod.month",
  [PayPeriodEnum.QUARTER]: "payPeriod.quarter",
  [PayPeriodEnum.EVERY_SIX_MONTHS]: "payPeriod.everySixMonths",
  [PayPeriodEnum.YEAR]: "payPeriod.year",
};

export const PayPeriodRounding = {
  [PayPeriodEnum.HOUR]: 2,
  [PayPeriodEnum.DAY]: 0,
  [PayPeriodEnum.WEEK]: 0,
  [PayPeriodEnum.EVERY_TWO_WEEKS]: 0,
  [PayPeriodEnum.SEMIMONTHLY]: 0,
  [PayPeriodEnum.MONTH]: 0,
  [PayPeriodEnum.QUARTER]: 0,
  [PayPeriodEnum.EVERY_SIX_MONTHS]: 0,
  [PayPeriodEnum.YEAR]: 0,
};
